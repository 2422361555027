import React, { useEffect } from 'react';
import { useState } from 'react';
import {Link} from 'react-router-dom';
import MobileMenu from './Menu/MobileMenu';
import Logo from  '../../assets/img/logos/logo.png'
import { shortenAddress } from '../../utils/shortenAddress';
import MegaMenu from './Menu/MegaMenu';
const CryptoMenu = [
  {
    icon: 'folder-keyhole',
    title: 'Ranking',
    link: '/ranking',
  },
  {
    icon: 'folder-keyhole',
    title: 'Recently Added',
    link: '/recently-added',
  },
  {
    icon: 'folder-keyhole',
    title: 'Categories',
    link: '/categories',
  },
  {
    icon: 'folder-keyhole',
    title: 'Spot Light',
    link: '/spot-light',
  },
  {
    icon: 'folder-keyhole',
    title: 'Gainers & Losers',
    link: '/gainers-losers',
  }
];
const ProductsMenu = [
  
  {
    icon: 'folder-keyhole',
    title: 'Crypto Converter',
    link: '/crypto-converter',
  },
  {
    icon: 'folder-keyhole',
    title: 'Crypto Screener',
    link: '/crypto-screener',
  },
  {
    icon: 'calendar',
    title: 'ICO/IPO Calendar',
    link: '/ico-ipo-calendar',
  },
  {
    icon: 'calendar',
    title: 'Event Calandar',
    link: '/event-calendar',
  },
  {
    icon: 'folder-keyhole',
    title: 'Referal Program',
    link: '/referal-program',
  },
  {
    icon: 'folder-keyhole',
    title: 'Free Airdrops',
    link: '/free-airdrops',
  }
  
];
const PagesMenu = [
  
  {
    title: 'Portfolio',
    link: '/portfolio',
  },
  {
    title: 'Watchlist',
    link: '/watchlist',
  },
  
  
];

const Header = () => {
  
  
  const [isActive, setActive] = useState(false);
  const [userInfo, setUserInfo] = useState();

  useEffect(() =>{
    
    if(localStorage.getItem('userInfo')){
      const connectedUser = JSON.parse(localStorage.getItem('userInfo'));
      setUserInfo(connectedUser);
      
    }
  },[])

   const logOut = async() =>{
    
    localStorage.removeItem('userInfo');
    setUserInfo(null);
    window.location.href="/";
   }

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <div>

      <header className="header__1">
        <div className="container">
          <div className="wrapper js-header-wrapper">
            <div className="header__logo">
              <Link to="/">
                <img
                  className="header__logo"
                  id="logo_js"
                  src='/img/logos/logo.png'
                  alt="logo"
                />
              </Link>
            </div>
            {/* ================== Menu */}
            <div className="header__menu">
              <ul className="d-flex space-x-20">
                <li className="has_popup">
                  <Link  to="/">
                  Cryptocurrencies
                  </Link>
                  <ul className="menu__popup space-y-20">
                    {CryptoMenu.map((val, i) => (
                      <li key={i}>
                        <Link to={val.link}>
                          <i className={`ri-${val.icon}-line`} />
                          {val.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                {PagesMenu.map((val, i) => (
                  <li key={i}>
                    <Link className="color_black" to={val.link}>
                      {val.title}
                    </Link>
                  </li>
                ))}
                <li className="has_popup">
                  <Link className='color_black' to="/">
                  Products
                  </Link>
                  <ul className="menu__popup space-y-20">
                    {ProductsMenu.map((val, i) => (
                      <li key={i}>
                        <Link to={val.link}>
                          <i className={`ri-${val.icon}-line`} />
                          {val.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <a className="color_black is_new hovered" href="https://marketplace.thetradingbloc.com" rel='noreferrer'  target="_blank">
                  NFT Marketplace
                  </a>

                {/* <li className="has_popup2">
                  <Link className="color_black is_new hovered" to="/">
                    Pages <i className="ri-more-2-fill" />
                  </Link>
                  <ul className="menu__popup2 space-y-20">
                    <MegaMenu />
                  </ul>
                </li> */}
              </ul>
            </div>
            {/* ================= */}
            <div className="header__search">
              <input type="text" placeholder="Search" />
              <Link to="no-results" className="header__result">
                <i className="ri-search-line" />
              </Link>
            </div>
            <div className="header__btns">
              {userInfo ? 
              <div>
             
              <li className="has_popup">
                  <Link className="color_black hovered" to="">
                  <button type="button" className="btn btn-primary">
                  { shortenAddress(userInfo?.accounts[0]) }</button>

                     {/* <i className="ri-more-2-fill" /> */}
                  </Link>
                  <ul className="menu__popup2 ">
                    <div className="col-md-6 space-y-10">
                      {CryptoMenu.map((val, i) => (
                        <li key={i}>
                          <Link to={val.link}>
                            <i className={`ri-${val.icon}-line`} />
                            {val.title}
                          </Link>
                        </li>
                      ))}
                      <li>
                        <Link to="" key={9} onClick={logOut} ><i className="ri-shut-down-line"></i>Logout</Link>
                      </li>
                    </div>
                  </ul>
                </li>
              </div>
              : 
              <div>
                <Link className="btn btn-grad btn-white  btn-sm" to="/connect-wallet">Login</Link>
                &nbsp;<Link className="btn btn-grad btn-sm" to="/connect-wallet">Sign Up</Link>
            </div>
              }
              
            </div>
            <div className="header__burger js-header-burger" onClick={toggleClass}/>
            <div className={` header__mobile js-header-mobile  ${isActive ? 'visible': null} `}>
            <MobileMenu />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
