import React from 'react';
import Card3 from '../../components/cards/Card3';
import RecentlyListed from '../../components/cards/RecentlyListed';
import useDocumentTitle from '../../components/useDocumentTitle';
import HotSellers from '../../components/creators/HotSellers';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Hero3 from '../../components/hero/Hero3';
import Call2actionLight from '../../components/Sections/Call2actionLight';
import HeroPreview from '../../components/hero/HeroPreview';
import CardMarketCards from '../../components/cards/CardMarketCards';
import CryptoPriceListTable from '../../components/tables/CryptoPriceListTable';

const HomePage = () => {
  useDocumentTitle('TheTradingBloc - Home');
  return (
    <div>
      <Header />
      <HeroPreview />
      <CryptoPriceListTable />
      {/* <Hero3 /> */}
      {/* <RecentlyListed />
      <Call2actionLight /> */}
      {/* <HotSellers /> */}
      
      {/* <Card3 /> */}
      <Footer />
    </div>
  );
};

export default HomePage;
