import { useState, createContext, useContext, useEffect } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) =>{

    const [user, setUser] = useState(null);
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));

    const login = user =>{
        localStorage.setItem('userInfo', JSON.stringify(user));
        setUser(user);
        setUserInfo(user);
    }

    const logout = () =>{
        setUser(null);
        localStorage.removeItem('userInfo');
    }
    
    useEffect(() =>{
    console.log('useEffect-AuthContext')
        if(localStorage.getItem('userInfo')){
            console.log('localStorage.getItem', localStorage.getItem('userInfo'))
          const connectedUser = JSON.parse(localStorage.getItem('userInfo'));
          setUserInfo(connectedUser);
          setUser(connectedUser);
          
        }
      },[])

    return <AuthContext.Provider value={{user, login, logout, userInfo}} >{children}</AuthContext.Provider>
}

export const useAuth = () =>{
    return useContext(AuthContext);
}