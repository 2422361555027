import React, {useRef} from 'react';
import { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Popup from 'reactjs-popup';
import Collection3 from '../../../components/collection/Collection3';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import useDocumentTitle from '../../../components/useDocumentTitle';
import { useMarketPlaceContext } from '../../../context/MarketPlaceContext';
import Loader from '../../../utils/Loader';
import {shortenAddress } from '../../../utils/shortenAddress';
import { ToastContainer, toast } from 'react-toastify';

const PurchasedNFT = () => {
  useDocumentTitle(' My Purchased NFTs');

  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  const navigate = useNavigate();

  const [formInput, updateFormInput] = useState({ price: ''})

  const {purchasedNFTs, loadingState, message, resellNFT} = useMarketPlaceContext();
  const nfts = purchasedNFTs;

  const ReSellHandler = async(nft) =>{
     
    if(!formInput.price){
      toast.error('Please enter the re-sell amount in ETH');
      return;
    }
    const reSellRequest = { id : nft.tokenId, price : formInput.price};
    //console.log(reSellRequest);

    await resellNFT(reSellRequest);
    if(message ==='success'){
      toast.success("Your item has been re-listed to marketplace");
      navigate('/marketplace');
    }else{
      toast.error("Something went wrong, please try again later");
    }
     
  }
  //if (loadingState === 'loaded' && !nfts.length) return (<h1 className="px-20 py-10 text-3xl">No NFT found!</h1>)
  return (
    <div>
      <Header />
      <div>
      <div>
        <div className="hero__collections">
        <ToastContainer position="top-right" />
          <div className="container">
            <h1> My Purchased NFTs</h1>
          </div>
        </div>
      </div>
    </div>
      <div className="section mt-100">
        
        <div className="container">
          {/* <Collection3 /> */}

          <div className="row">
          {
  loadingState === 'loading' ? <Loader />
   : ''
}
            {nfts.map((val, i) => (
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={i}>
                <div className="card__item four">
                  <div className="card_body space-y-10">
                    {/* =============== */}
                    <div className="creators space-x-10">
                    <div className="avatars space-x-3">
                    <Link to="profile">
                      <img
                        src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAAAXNSR0IArs4c6QAAA65JREFUeF7t3b11FTEQhmHdMhxzKIAG3ACEOCdxBe7EKYlzE+IeyKiAmDJMEU8wzNn35lpJ37yaH61W9/b26+P7Gfx9+vZIvf9++U7ttfH28d8CwBAIANPvbBdw+/jzABcHOAACoCRQGCgEiHrnlAMMVzGFgIsDHAABUA4gDJQDiHrlAGd6J7MQcHGAAyAAygGEgXIAUa8coBxg+wraPv7b89M9nQf48PCXfIAK+OPzT+pfG399+0KP0Crgz+sd9R8AJN85AZAHIITyAHgkrBBQCKAVqI0LAYUAYqgQUAgggKoCKgMJoMpAkq8y8LQR1EYQraF2Au3LpnKAcgBagOUAJF85QDlAL4N6GyhOpI2gNoKEn8NJoH4ePp3F6148qX/O0ZdROn71IHwoNADsQEoAYBmnAuYB8IqYPEAegBbRdAylwZcD+OfdATD7LqEkEF3ANMBVAbgTh/avDCwJLAmkRTTtQmnwJYElgdMAlwOUA5ATqwog+XoXwNe8TbtQtH9VQFVAVQAtojzA8p1Asv45fKBBj6VfffycBF5dQD2RMw1wACDBAXBxAQMgAEiBQgBecjQtYB6A+K8KmAa4JPDiAAdAAMzeFbw9hm4ffx4gD5AHEAbyAKJe7wL483qU/xQCUME8wMUFDIAAIAUuvxFE6tWYFRjPAXgGPYAUCACSb3/jANhvQ5pBAJB8+xsHwH4b0gwCgOTb3zgA9tuQZhAAJN/+xgGw34Y0gwAg+fY3DoD9NqQZBADJt79xAOy3Ic0gAEi+/Y0DYL8NaQYMgN4Qordc6YkcUu8cPtM3rV8AIAF6oicAXmb/Nw/tnweYJrgQYAuoEIAuoBCA//pVEvhICKp+eQCSvyqAbwpVgssBygFwDVvzcoByACJouooqByDzlQOUAzzM/nm25lB5gDyA3RAyHcOqAqoCcA1b86qAqgAiaNqD3p6f7t9pBjVerUAArDafDz4AXMPVTwiA1ebzwQeAa7j6CQGw2nw++ABwDVc/IQBWm88HHwCu4eonBMBq8/ngA8A1XP2EAFhtPh98ALiGq58QAKvN54MPANdw9RMCYLX5fPAMgJ6I0SNd2r9KOD1+7T8AkAA1gAKs/QdAANiRsGmCtX+0/9EVqOPX/vMASIAaIABe78gEKiB1/h/88aUCmAdAAtQACrD2HwABUBIoDOgKzAOUAwh/XIUUAkj+wwbIA+QBCEENQXkAkj8PwFelKsHqQtH+hQA1QADYFTOqXyEAXYAaYHoB/QN3Oz9txZiQNwAAAABJRU5ErkJggg=='
                        alt="Avatar"
                        className="avatar avatar-sm"
                      />
                    </Link>
                    <a rel="noreferrer" target="_blank" href={'https://rinkeby.etherscan.io/address/'+val?.owner}>
                      <p className="avatars_name txt_xs">@{shortenAddress(val.owner)}</p>
                    </a>
                  </div>
                    </div>
                    <div className="card_head">
                      <Link to="/item-details" state={val}>
                        <img src={val.image} alt="prrv" />
                      </Link>
                      
                    </div>
                    {/* =============== */}
                    <h6 className="card_title">
                      <Link className="color_black" to="/item-details">
                        {val.name}
                      </Link>
                    </h6>
                    <div className="card_footer d-block space-y-10">
                      <div className="card_footer justify-content-between">
                        <div className="creators">
                          <p className="txt_sm"> 1 in stock</p>
                        </div>
                        <div className="txt_sm">
                          {/* Price: */}
                          <span
                            className="color_green
                                                        txt_sm">
                            {val.price} ETH
                          </span>
                        </div>
                      </div>
                      <div className="hr" />
                      
                      <input type='number'
          placeholder="Asset Price in Eth"
          className="form-control" step="0.001" min="0.01" max="5"
          
          onChange={e => updateFormInput({ ...formInput, price: e.target.value })}
        />
                      <div
                    className="d-flex
								align-items-center
								space-x-10
								justify-content-between">
                    <div
                      className="d-flex align-items-center
									space-x-5">
                      <i className="ri-history-line" />
                      <Popup
                        className="custom"
                        ref={ref}
                        trigger={
                          <button className="popup_btn">
                            <p
                              className="color_text txt_sm view_history"
                              style={{width: 'auto'}}>
                               History
                            </p>
                          </button>
                        }
                        position="bottom center">
                        <div>
                          <div
                            className="popup"
                            id="popup_bid"
                            tabIndex={-1}
                            role="dialog"
                            aria-hidden="true">
                            <div>
                              <button
                                type="button"
                                className="button close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeTooltip}>
                                <span aria-hidden="true">×</span>
                              </button>
                              <div className="space-y-20">
                                <h4> History </h4>
                                <div className="creator_item creator_card space-x-10">
                                  <div className="avatars space-x-10">
                                    <div className="media">
                                      <div className="badge">
                                        <img
                                          src={`img/icons/Badge.svg`}
                                          alt="Badge"
                                        />
                                      </div>
                                      <Link to="profile">
                                        <img
                                          src={`img/avatars/avatar_1.png`}
                                          alt="Avatar"
                                          className="avatar avatar-md"
                                        />
                                      </Link>
                                    </div>
                                    <div>
                                      <p className="color_black">
                                        Bid accepted
                                        <span className="color_brand">
                                          1 ETH
                                        </span>
                                        by
                                        <Link
                                          className="color_black txt
						_bold"
                                          to="profile">
                                          ayoub
                                        </Link>
                                      </p>
                                      <span className="date color_text">
                                        28/06/2021, 12:08
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="creator_item creator_card space-x-10">
                                  <div className="avatars space-x-10">
                                    <div className="media">
                                      <div className="badge">
                                        <img
                                          src={`img/icons/Badge.svg`}
                                          alt="Badge"
                                        />
                                      </div>
                                      <Link to="profile">
                                        <img
                                          src={`img/avatars/avatar_2.png`}
                                          alt="Avatar"
                                          className="avatar avatar-md"
                                        />
                                      </Link>
                                    </div>
                                    <div>
                                      <p className="color_black">
                                        Bid accepted
                                        <span className="color_brand">
                                          3 ETH
                                        </span>
                                        by
                                        <Link
                                          className="color_black txt
						_bold"
                                          to="profile">
                                          monir
                                        </Link>
                                      </p>
                                      <span className="date color_text">
                                        22/05/2021, 12:08
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Popup>
                      
                    </div>
                    
                    <button onClick={() => ReSellHandler(val)} className="btn btn-sm btn-primary">
                          Re-Sell
                        </button>

                        
                    {/* <Popup
                      className="custom"
                      ref={ref}
                      trigger={
                        <button className="btn btn-sm btn-primary">
                          Place Order
                        </button>
                      }
                      position="bottom center">
                      <div>
                        <div
                          className="popup"
                          id="popup_bid"
                          tabIndex={-1}
                          role="dialog"
                          aria-hidden="true">
                          <div>
                            <button
                              type="button"
                              className="button close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={closeTooltip}>
                              <span aria-hidden="true">×</span>
                            </button>
                            <div className=" space-y-20">
                              <h3>Place a Bid</h3>
                              <p>
                                You must bid at least
                                <span className="color_black">15 ETH</span>
                              </p>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="00.00 ETH"
                              />
                              <p>
                                Enter quantity.
                                <span className="color_green">5 available</span>
                              </p>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={1}
                              />
                              <div className="hr" />
                              <div className="d-flex justify-content-between">
                                <p> You must bid at least:</p>
                                <p className="text-right color_black txt _bold">
                                  67,000 ETH
                                </p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p> service free:</p>
                                <p className="text-right color_black txt _bold">
                                  0,901 ETH
                                </p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p> Total bid amount:</p>
                                <p className="text-right color_black txt _bold">
                                  56,031 ETH
                                </p>
                              </div>
                              <Popup
                                className="custom"
                                ref={ref}
                                trigger={
                                  <button className="btn btn-primary w-full">
                                    Place a bid
                                  </button>
                                }
                                position="bottom center">
                                <div>
                                  <div
                                    className="popup"
                                    id="popup_bid"
                                    tabIndex={-1}
                                    role="dialog"
                                    aria-hidden="true">
                                    <div>
                                      <button
                                        type="button"
                                        className="button close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={closeTooltip}>
                                        <span aria-hidden="true">×</span>
                                      </button>
                                      <div className="space-y-20">
                                        <h3 className="text-center">
                                          Your Bidding Successfuly Added
                                        </h3>
                                        <p className="text-center">
                                          your bid
                                          <span
                                            className="color_text txt
      _bold">
                                            (16ETH)
                                          </span>
                                          has been listing to our database
                                        </p>
                                        <Link
                                          to="#"
                                          className="btn btn-dark w-full">
                                          Watch the listings
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Popup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popup> */}
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PurchasedNFT;
