import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const HeroPreview = () => {

  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

  return (
    <div className="hero__index">
      <img className="sh_1" src="img/icons/sh_1.png" alt="ImgPreview" />
      <img className="sh_2" src="img/icons/sh_2.png" alt="ImgPreview" />
      <img className="sh_3" src="img/icons/sh_3.png" alt="ImgPreview" />

      <div className="card-group card-padding ">
        <div className="card">
          <img src="https://s2.coinmarketcap.com/static/new-alerts/611521ecc3074e0013b0c4a9/img/1656033637688_(2)%20Airdrop-280x136.png" className="card-img-top" alt="..." />
          <div className="card-body">
            <p className="card-text"><small className="text-muted">Free Airdrop</small></p>
            <h5 className="card-title">Join the free airdrop campaign</h5>
            {/* <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> */}

          </div>
        </div>
        <div className="card">
          <img src="https://s2.coinmarketcap.com/static/new-alerts/6115224cb91ba00012e951d7/img/1656033739021_Learn-and-Earn-280x136.jpeg" className="card-img-top" alt="..." />
          <div className="card-body">
            <p className="card-text"><small className="text-muted">Learn About Cryptos</small></p>
            <h5 className="card-title">Learn About Cryptos</h5>
            {/* <p className="card-text">This card has supporting text below as a natural lead-in to additional content.</p> */}

          </div>
        </div>
        <div className="card">
          <img src="https://s2.coinmarketcap.com/static/new-alerts/613f25cbef9a5a001905f323/img/1656079638630_KCC-Chain-Thumbnail-280x136.jpg" className="card-img-top" alt="..." />
          <div className="card-body">
            <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
            <h5 className="card-title">Card title</h5>
            {/* <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p> */}

          </div>
        </div>
        <div className="card">
          <img src="https://s2.coinmarketcap.com/static/new-alerts/61eaf7492f29840013760da4/img/1656058306705_2022-06-24%2015.08.42.jpg" className="card-img-top" alt="..." />
          <div className="card-body">
            <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
            <h5 className="card-title">Card title</h5>
            {/* <p className="card-text">This card has supporting text below as a natural lead-in to additional content.</p> */}

          </div>
        </div>

      </div>
      <div className="container">
        {/* <div classNameName="row justify-content-center">
          <div classNameName="col-lg-8">
            <div classNameName="box is__big hero_icons">
              <div classNameName="row">
                <div classNameName="col-lg-4 space-y-10 text-center">
                  <i classNameName="ri-star-line icon is_1" />
                  <p>Rating:</p>
                  <div
                    classNameName="stars d-flex justify-content-center"
                    style={{color: '#FFC700'}}>
                    <i classNameName="ri-star-fill" />
                    <i classNameName="ri-star-fill" />
                    <i classNameName="ri-star-fill" />
                    <i classNameName="ri-star-fill" />
                    <i classNameName="ri-star-fill" />
                    <span classNameName="color_black">/ 5*</span>
                  </div>
                </div>
                <div classNameName="col-lg-4 space-y-10 text-center">
                  <i classNameName="ri-price-tag-3-line icon is_2" />
                  <p>Price:</p>
                  <p classNameName="color_black">
                    Starting at <strong> $29</strong>
                  </p>
                </div>
                <div classNameName="col-lg-4 space-y-10 text-center">
                  <i classNameName="ri-information-line icon is_3" />
                  <p>Latest Version:</p>
                  <p classNameName="color_black">
                    <span> 0.0.1</span> , 20 Nov 2021
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HeroPreview;
