import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import SidebarBlog from '../../../components/sidebars/SidebarBlog';
import useDocumentTitle from '../../../components/useDocumentTitle';

const Disclaimer = () => {
  useDocumentTitle('Disclaimer');
  return (
    <div>
      <Header />
      <div className="container article_page pt-100">
        <div className="main row" id="main-content">
          <div className="col-lg-8">
            <div id="content">
              <div className="article_wrap mt-0">
                <div className="content">

                  <h1 className="mb-40">
                    Disclaimer
                  </h1>

                  <div className="inner">
                    <div className="snippet">
                      <h6>
                        Disclaimer
                      </h6>
                      <h6>
                        Last Updated: May 12, 2022
                      </h6>
                      <p>
                        Certain information contained herein and certain oral statements made are forward-looking and relate to BlocUnited LLC (“BLOCUNITED”) strategy, product development, the timing of product development, events and courses of action. Statements which are not purely historical are forward-looking statements and include any statements regarding beliefs, plans, outlook, expectations or intentions regarding the future including words or phrases such as “anticipate,” “objective,” “may,” “will,” “might,” “should,” “could,” “can,” “intend,” “expect,” “believe,” “estimate,” “predict,” “potential,” “plan,” “is designed to” or similar expressions suggest future outcomes or the negative thereof or similar variations. Forward-looking statements may include, among other things, statements about: our expectations regarding our expenses, sales and operations; our future customer concentration; our anticipated cash needs and our estimates regarding our capital requirements and our need for additional financing; our ability to anticipate the future needs of our customers; our plans for future products and enhancements of existing products; our future growth strategy and growth rate; our future intellectual property; and our anticipated trends and challenges in the markets in which we operate. Such statements and information are based on numerous assumptions regarding present and future strategies and the environment in which BLOCUNITED will operate in the future, including the demand for our products, anticipated costs and ability to achieve goals. Although we believe that the assumptions underlying these statements are reasonable, they may prove to be incorrect. Given these risks, uncertainties and assumptions, you should not place undue reliance on these forward-looking statements.
                      </p>
                      <p>
                        Forward-looking statements are subject to known and unknown risks, uncertainties and other important factors that may cause the actual results to be materially different from those expressed or implied by such forward-looking statements, including but not limited to, business, economic and capital market conditions; the ability to manage our operating expenses, which may adversely affect our financial condition; our ability to remain competitive as other better financed competitors develop and release competitive products; regulatory uncertainties; market conditions and the demand and pricing for our products; our relationships with our customers, distributors and partners; our ability to successfully define, design and release new products in a timely manner that meet our customers‟ needs; our ability to attract, retain and motivate qualified personnel; competition in our industry; our ability to maintain technological leadership; our ability to manage risks inherent in foreign operations; the impact of technology changes on our products and industry; our failure to develop new and innovative products; our ability to successfully maintain and enforce our intellectual property rights and defend third-party claims of infringement of their intellectual property rights; the impact of intellectual property litigation that could materially and adversely affect our project; our ability to manage working capital; and our dependence on key personnel. BLOCUNITED is an early-stage company with a short operating history; it may never receive any product sales revenue or achieve profitability; and it may not actually achieve its plans, projections, or expectations.
                      </p>

                    </div>

                    <p>
                      Important factors that could cause actual results to differ materially from BLOCUNITED’s expectations include, consumer sentiment towards BLOCUNITED’s products and blockchain technology generally, litigation, global economic climate, loss of key employees and consultants, additional funding requirements, changes in laws, technology failures, competition, and failure of counterparties to perform their contractual obligations.
                    </p>

                    <p>
                      Except as required by law, we undertake no obligation to update or revise any forward-looking statements, whether as a result of new information, future event or otherwise, after the date on which the statements are made or to reflect the occurrence of unanticipated events. Neither we nor any of our representatives make any representation or warranty, express or implied, as to the accuracy, sufficiency or completeness of the information in this presentation. Neither we nor any of our representatives shall have any liability whatsoever, under contract, tort, trust or otherwise, to you or any person resulting from the use of the information in this presentation by you or any of your representatives or for omissions from the information in this website.
                    </p>

                    <p>
                      The native tokens in the digital ecosystems that BlocUnited develops (the “Tokens”) do not constitute an investment and is not a security. The Tokens do not represent any claim for repayment of a monetary sum against BlocUnited LLC nor have persons holding Tokens (“Token Holders”) any claim against BlocUnited LLC for payment of interests or for sharing of profits generated by BlocUnited. A total loss of the value of Tokens or any investment due to various causes cannot be excluded. BlocUnited is not involved with anything associated the token generating events associated with the Tokens.
                    </p>
                    <p>
                      An acquisition of Tokens is suitable only for experienced persons who are in a position to evaluate the risks, including the risks related to the underlying technology, and who have sufficient resources to be able to bear any losses, including a complete loss, which may result from such acquisition. Before subscribing to or otherwise acquiring any Tokens, prospective investors should  specifically ensure that they understand the structure of, and the risk inherent to, the Tokens.
                    </p>

                    <p>
                      Prior to the acquisition of Token, persons should independently assess any risks, seek advice with respect to the economic, legal, regulatory and tax implications of the purchase of Token and should consult with his/her own investment, legal, tax, accounting, or other advisors to determine the potential benefits, burdens, risks, and other consequences of a purchase of Token.
                    </p>

                    <p>
                      Prospective Token Holders are required to study the white paper, the website, the FAQs, and all other available information sources, and are encouraged to clarify all their questions prior to the acquisition of Tokens.
                    </p>



                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <SidebarBlog />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Disclaimer;
