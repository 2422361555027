import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import Popup from 'reactjs-popup';
import { useAuth } from '../../context/AuthContext';
import { shortenAddress } from '../../utils/shortenAddress';

const HeroProfile = () => {
  const ref = useRef();
  const [isActive, setActive] = useState(false);
  const toggleFollow = () => {
    setActive(!isActive);
  };
  const [isShare, setShare] = useState(false);

  const toggleShare = () => {
    setShare(!isShare);
  };
  const [isMore, setMore] = useState(false);

  const toggleMore = () => {
    setMore(!isMore);
  };

  const auth = useAuth();
  console.log('auth',auth.userInfo);
  const userInfo = auth.userInfo;

  return (
    <div className="mb-100">
      <div className="hero__profile">
        <div className="cover">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAAAXNSR0IArs4c6QAAA65JREFUeF7t3b11FTEQhmHdMhxzKIAG3ACEOCdxBe7EKYlzE+IeyKiAmDJMEU8wzNn35lpJ37yaH61W9/b26+P7Gfx9+vZIvf9++U7ttfH28d8CwBAIANPvbBdw+/jzABcHOAACoCRQGCgEiHrnlAMMVzGFgIsDHAABUA4gDJQDiHrlAGd6J7MQcHGAAyAAygGEgXIAUa8coBxg+wraPv7b89M9nQf48PCXfIAK+OPzT+pfG399+0KP0Crgz+sd9R8AJN85AZAHIITyAHgkrBBQCKAVqI0LAYUAYqgQUAgggKoCKgMJoMpAkq8y8LQR1EYQraF2Au3LpnKAcgBagOUAJF85QDlAL4N6GyhOpI2gNoKEn8NJoH4ePp3F6148qX/O0ZdROn71IHwoNADsQEoAYBmnAuYB8IqYPEAegBbRdAylwZcD+OfdATD7LqEkEF3ANMBVAbgTh/avDCwJLAmkRTTtQmnwJYElgdMAlwOUA5ATqwog+XoXwNe8TbtQtH9VQFVAVQAtojzA8p1Asv45fKBBj6VfffycBF5dQD2RMw1wACDBAXBxAQMgAEiBQgBecjQtYB6A+K8KmAa4JPDiAAdAAMzeFbw9hm4ffx4gD5AHEAbyAKJe7wL483qU/xQCUME8wMUFDIAAIAUuvxFE6tWYFRjPAXgGPYAUCACSb3/jANhvQ5pBAJB8+xsHwH4b0gwCgOTb3zgA9tuQZhAAJN/+xgGw34Y0gwAg+fY3DoD9NqQZBADJt79xAOy3Ic0gAEi+/Y0DYL8NaQYMgN4Qordc6YkcUu8cPtM3rV8AIAF6oicAXmb/Nw/tnweYJrgQYAuoEIAuoBCA//pVEvhICKp+eQCSvyqAbwpVgssBygFwDVvzcoByACJouooqByDzlQOUAzzM/nm25lB5gDyA3RAyHcOqAqoCcA1b86qAqgAiaNqD3p6f7t9pBjVerUAArDafDz4AXMPVTwiA1ebzwQeAa7j6CQGw2nw++ABwDVc/IQBWm88HHwCu4eonBMBq8/ngA8A1XP2EAFhtPh98ALiGq58QAKvN54MPANdw9RMCYLX5fPAMgJ6I0SNd2r9KOD1+7T8AkAA1gAKs/QdAANiRsGmCtX+0/9EVqOPX/vMASIAaIABe78gEKiB1/h/88aUCmAdAAtQACrD2HwABUBIoDOgKzAOUAwh/XIUUAkj+wwbIA+QBCEENQXkAkj8PwFelKsHqQtH+hQA1QADYFTOqXyEAXYAaYHoB/QN3Oz9txZiQNwAAAABJRU5ErkJggg==" alt="ImgPreview" />
        </div>
        <div className="infos">
          <div className="container">
            <div className="row flex-wrap align-items-center justify-content-between sm:space-y-50">
              <div className="col-md-auto mr-20">
                <div className="avatars d-flex space-x-20 align-items-center">
                  <div className="avatar_wrap">
                    <img
                      className="avatar avatar-lg"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAAAXNSR0IArs4c6QAAA65JREFUeF7t3b11FTEQhmHdMhxzKIAG3ACEOCdxBe7EKYlzE+IeyKiAmDJMEU8wzNn35lpJ37yaH61W9/b26+P7Gfx9+vZIvf9++U7ttfH28d8CwBAIANPvbBdw+/jzABcHOAACoCRQGCgEiHrnlAMMVzGFgIsDHAABUA4gDJQDiHrlAGd6J7MQcHGAAyAAygGEgXIAUa8coBxg+wraPv7b89M9nQf48PCXfIAK+OPzT+pfG399+0KP0Crgz+sd9R8AJN85AZAHIITyAHgkrBBQCKAVqI0LAYUAYqgQUAgggKoCKgMJoMpAkq8y8LQR1EYQraF2Au3LpnKAcgBagOUAJF85QDlAL4N6GyhOpI2gNoKEn8NJoH4ePp3F6148qX/O0ZdROn71IHwoNADsQEoAYBmnAuYB8IqYPEAegBbRdAylwZcD+OfdATD7LqEkEF3ANMBVAbgTh/avDCwJLAmkRTTtQmnwJYElgdMAlwOUA5ATqwog+XoXwNe8TbtQtH9VQFVAVQAtojzA8p1Asv45fKBBj6VfffycBF5dQD2RMw1wACDBAXBxAQMgAEiBQgBecjQtYB6A+K8KmAa4JPDiAAdAAMzeFbw9hm4ffx4gD5AHEAbyAKJe7wL483qU/xQCUME8wMUFDIAAIAUuvxFE6tWYFRjPAXgGPYAUCACSb3/jANhvQ5pBAJB8+xsHwH4b0gwCgOTb3zgA9tuQZhAAJN/+xgGw34Y0gwAg+fY3DoD9NqQZBADJt79xAOy3Ic0gAEi+/Y0DYL8NaQYMgN4Qordc6YkcUu8cPtM3rV8AIAF6oicAXmb/Nw/tnweYJrgQYAuoEIAuoBCA//pVEvhICKp+eQCSvyqAbwpVgssBygFwDVvzcoByACJouooqByDzlQOUAzzM/nm25lB5gDyA3RAyHcOqAqoCcA1b86qAqgAiaNqD3p6f7t9pBjVerUAArDafDz4AXMPVTwiA1ebzwQeAa7j6CQGw2nw++ABwDVc/IQBWm88HHwCu4eonBMBq8/ngA8A1XP2EAFhtPh98ALiGq58QAKvN54MPANdw9RMCYLX5fPAMgJ6I0SNd2r9KOD1+7T8AkAA1gAKs/QdAANiRsGmCtX+0/9EVqOPX/vMASIAaIABe78gEKiB1/h/88aUCmAdAAtQACrD2HwABUBIoDOgKzAOUAwh/XIUUAkj+wwbIA+QBCEENQXkAkj8PwFelKsHqQtH+hQA1QADYFTOqXyEAXYAaYHoB/QN3Oz9txZiQNwAAAABJRU5ErkJggg=="
                      alt="avatar"
                    />
                  </div>
                  <h5>{userInfo.displayUserName}</h5>
                </div>
              </div>
              <div className="col-md-auto">
                <div className="d-sm-flex flex-wrap align-items-center space-x-20 mb-20_reset d-sm-block">
                  <div className="mb-20">
                    <div className="copy">
                      <span className="color_text"> {shortenAddress(userInfo.ethAddress)} </span>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap align-items-center space-x-20">
                    <div className="mb-20">
                      {/* <div
                        className={`btn btn-dark btn-sm ${
                          isActive ? 'btn-prim' : null
                        } `}
                        onClick={toggleFollow}>
                        Follow{isActive ? 'ing' : null}
                      </div> */}
                      <Link className='btn btn-dark btn-sm' to='/update-profile' >Update Profile</Link>
                    </div>
                    <div className="mb-20">
                      <div className="share">
                        <div className="icon" onClick={toggleShare}>
                          <i className="ri-share-line"></i>
                        </div>
                        <div
                          className={`dropdown__popup ${
                            isShare ? 'visible' : null
                          } `}>
                          <ul className="space-y-10">
                            <li>
                            <a href="https://www.facebook.com/" rel="noreferrer"  target="_blank">
                                <i className="ri-facebook-line"></i>
                              </a>
                            </li>
                            <li>
                            <a href="https://www.messenger.com/" rel="noreferrer"  target="_blank">
                                <i className="ri-messenger-line"></i>
                              </a>
                            </li>
                            <li>
                            <a href="https://whatsapp.com" target="_blank" rel="noreferrer" >
                                <i className="ri-whatsapp-line"></i>
                              </a>
                            </li>
                           
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="mb-20">
                      <div className="more">
                        <div className="icon" onClick={toggleMore}>
                          <i className="ri-more-fill"></i>
                        </div>
                        <div
                          className={`dropdown__popup ${
                            isMore ? 'visible' : null
                          } `}>
                          <ul className="space-y-10">
                            <li>
                              <Popup
                                className="custom"
                                ref={ref}
                                trigger={
                                  <Link
                                    to="#"
                                    className="content space-x-10 d-flex">
                                    <i className="ri-flag-line" />
                                    <span> Report </span>
                                  </Link>
                                }
                                position="bottom center">
                                <div>
                                  <div
                                    className="popup"
                                    id="popup_bid"
                                    tabIndex={-1}
                                    role="dialog"
                                    aria-hidden="true">
                                    <div>
                                      <div className="space-y-20">
                                        <h3>
                                          Thank you, we've received your report
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Popup>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroProfile;
