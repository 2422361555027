import React from "react";
import Preview from "../views/Preview";


// All HOME PAGE ROUTES

import Home1 from "../views/homes/Home1"
import Home2 from "../views/homes/Home2"
import Home3 from "../views/homes/Home3"
import HomePage from "../views/homes/Home";

//  Account inner pages
import ConnectWalllet from "../views/pages/account/ConnectWalllet"
import EditProfile from "../views/pages/account/EditProfile"
import Login from "../views/pages/account/Login"
import Profile from "../views/pages/account/Profile"
import Register from "../views/pages/account/Register"

//  Blog inner pages
import Blog from "../views/pages/blog/Blog"
import Article from "../views/pages/blog/Article"

//  item inner pages

import ItemDetails from "../views/pages/item/ItemDetails"
import Upload from "../views/pages/item/Upload"
import UploadType from "../views/pages/item/UploadType"

// NftPages
import Collections from "../views/pages/NftPages/Collections"
import Creators from "../views/pages/NftPages/Creators"
import LiveAuctions from "../views/pages/NftPages/LiveAuctions"
import Marketplace from "../views/pages/NftPages/Marketplace"
import Ranking from "../views/pages/NftPages/Ranking"
import UpcomingProjects from "../views/pages/NftPages/UpcomingProjects"

// other pages
import Activity from "../views/pages/others/Activity"
import Newsletter from "../views/pages/others/Newsletter"
import NoResults from "../views/pages/others/NoResults"
import PrivacyPolicy from "../views/pages/others/PrivacyPolicy"
import NotFound from "../views/NotFound"
import Chat from "../views/pages/Support/Chat"
import SubmitRequest from "../views/pages/Support/SubmitRequest"
import Faq from "../views/pages/Support/Faq"
import Forum from "../views/pages/forum/Forum"
import PostDetails from "../views/pages/forum/PostDetails"
import Contact from "../views/pages/Support/Contact"
import UpdateProfile from "../views/pages/account/UpdateProfile";






// Route Specific


import { Routes, Route } from "react-router-dom";
import { RequireAuth } from "../views/pages/account/RequireAuth";
import PlaceOrder from "../components/common/PlaceOrder";
import PurchasedNFT from "../views/pages/NftPages/PurchasedNFT";
import Terms from "../views/pages/terms/Terms";
import Disclaimer from "../views/pages/terms/Disclaimer";
import NFTPurchaseAgreement from "../views/pages/terms/NFTPurchaseAgreement";
const AppRoutes = () => {
    return (
        <>
            <Routes>
                
                    <Route  path="/" exact element={ <HomePage />} />
                    {/* <Route exact path="/" component={Preview} /> */}
                    <Route path="/home-1" component={Home1} />
                    <Route path="/home-2" component={Home2} />
                    <Route path="/home-3" component={Home3} />
                    {/* inner pages */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/blog" component={Blog} />
                    <Route path="/article" component={Article} />
                    {/* <Route exact path="/marketplace/item-details" element={<ItemDetails />} /> */}
                    <Route path="/upload" element={<Upload />} />
                    <Route path="/upload-type" element={<UploadType />} />


                    <Route path="/purchased-nft" element={<PurchasedNFT />} />
                    <Route path="item-details"  >
                        <Route index={true} element={<ItemDetails />}></Route>
                        <Route path="place-order" element={<RequireAuth><PlaceOrder /></RequireAuth>} />
                    </Route>
                    <Route path="/connect-wallet" element={<ConnectWalllet />} />
                    <Route path="profile"  >
                        <Route index={true} element={<RequireAuth><Profile /></RequireAuth>}></Route>
                        <Route path="item-details"  >
                            <Route index={true} element={<ItemDetails />} ></Route>
                            <Route path="place-order" element={<RequireAuth><PlaceOrder /></RequireAuth>} />
                        </Route>
                    </Route>
                
                    <Route path="/update-profile" element={<RequireAuth><UpdateProfile /></RequireAuth>} />
                    <Route path="collections"  >
                        <Route index={true} element={<Collections />}></Route>
                        <Route path="item-details"  >
                            <Route index={true} element={<ItemDetails />} ></Route>
                            <Route path="place-order" element={<RequireAuth><PlaceOrder /></RequireAuth>} />
                        </Route>
                    </Route>
                    <Route path="creators" >
                        <Route index={true} element={<Creators />} ></Route>
                        <Route path="connect-wallet" element ={<ConnectWalllet />} />
                    </Route>
                    <Route path="marketplace" >
                        <Route index={true} element={<Marketplace />} ></Route>
                        <Route path="item-details"  >
                            <Route index={true} element={<ItemDetails />} ></Route>
                            <Route path="place-order" element={<RequireAuth><PlaceOrder /></RequireAuth>} />
                        </Route>
                    </Route>
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/disclaimer" element={<Disclaimer />} />
                    <Route path="/nft-purchase-agreement" element={<NFTPurchaseAgreement />} />
                    
                        
                    
                    <Route path="/live-auctions" element={<LiveAuctions />} />
                    
                    <Route path="/ranking" component={Ranking} />
                    <Route path="/upcoming-projects" element={<UpcomingProjects />} />
                    <Route path="/activity" element={<Activity />} />
                    <Route path="/newsletter" component={Newsletter} />
                    <Route path="/chat" component={Chat} />
                    <Route path="/submit-request" element={<SubmitRequest />} />
                    <Route path="/no-results" element={<NoResults />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/forum" component={Forum} />
                    <Route path="/post-details" component={PostDetails} />
                    <Route path="/contact" element={<Contact/>} />
                    <Route path="*" element={<NotFound />} />

                
            </Routes>
        </>
    );
};

export default AppRoutes;
