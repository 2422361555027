import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import 'reactjs-popup/dist/index.css';
import { useMarketPlaceContext } from '../../context/MarketPlaceContext';
import Loader from '../../utils/Loader';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import HeroPlaceOrder from '../hero/HeroPlaceOrder';
import useDocumentTitle from '../useDocumentTitle';


function PlaceOrder() {

    const location = useLocation();
    const NFTDetail = location.state;
    
    const marketPlaceContext = useMarketPlaceContext();
    const navigate = useNavigate();

    const placeOrderHandler = async() =>{
        try {

        const nftRequest = { price : NFTDetail.price, tokenId : NFTDetail.tokenId};
         await marketPlaceContext.buyNFT(nftRequest);
        toast.success("NFT has been purchased successfully.");
         navigate('/purchased-nft');
        //  if(marketPlaceContext.message ==='success'){
        //     toast.success("Your order has been placed, check your purchased item");
        //     navigate('/purchased-nft');
        //   }else{
        //     toast.error("Something went wrong, please try again later");
        //   }
        } catch (error) {
            toast.error(error.message);
        }
        
    }
    useDocumentTitle('Place Order')
    return (
        <div>
            <Header />
            {/* <HeroPlaceOrder /> */}
            <div className="hero__upload">
            <div className="container">
                <div>
                    <ToastContainer position="bottom-right" />
                    <div className="space-y-20">
                        <h3>Checkout</h3>
                        <img src={NFTDetail.image} height="200" width='200' className="img-thumbnail img-fluid" alt={NFTDetail.name} />
                        <p>
                            You are about to purchase a 
                            <span className="color_green"> {NFTDetail.name} </span>
                            from 
                            <span className="color_green"> Seller : {NFTDetail.seller}</span>
                        </p>
                        <div className="space-y-10">
                            <p>You pay (ETH)</p>
                            <input
                                type="text"
                                className="form-control"
                                readOnly
                                value={NFTDetail.price}
                                
                            />
                        </div>
                        <div className="space-y-10">
                            <p>
                                Quantity
                                <span className="color_green"> (1 available)</span>
                            </p>
                            <input
                                type="number"
                                readOnly
                                className="form-control"
                                defaultValue={1}
                            />
                        </div>
                        <div className="hr" />
                        <div className="d-flex justify-content-between">
                            <p> Item cost:</p>
                            <p className="text-right color_black txt _bold">
                                {NFTDetail.price} ETH
                            </p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p> Service fee (3.5%):</p>
                            <p className="text-right color_black txt _bold">
                                {(parseFloat(NFTDetail.price) * 3.5 /100).toFixed(4)} ETH
                            </p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p> Total amount:</p>
                            <p className="text-right color_black txt _bold">
                                {(parseFloat((NFTDetail.price) * 3.5 /100) + parseFloat(NFTDetail.price)).toFixed(4)} ETH
                            </p>
                        </div>

                        {
                  marketPlaceContext.loadingState === 'loading' ? <Loader /> : 
                  <button onClick={placeOrderHandler} className="btn btn-lg btn-primary w-full">
                            Buy Now
                        </button>
                }
                        
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </div>
    );
}
export default PlaceOrder;