import React, {useRef} from 'react';
import {Link} from 'react-router-dom';
import Countdown from 'react-countdown';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { useMarketPlaceContext } from '../../context/MarketPlaceContext';
import Loader from '../../utils/Loader';

const CardItems1 = [
  {
    img: '5',
    title: 'Colorful Abstract Painting',
    avatar_img1: '1',
    avatar_img2: '2',
    avatar_name: 'xander_hall',
    price: '0,054',
  },
  {
    img: '7',
    title: 'Spider Eyes Modern Art ',
    avatar_img1: '4',
    avatar_img2: '3',
    avatar_name: 'nathan_wall',
    price: '0,006',
  },
];
const CardItems2 = [
  {
    img: '6',
    title: 'Colorful Abstract Painting',
    avatar_img: '4',
    avatar_name: 'krista_bryan',
    price: '4,906',
  },
  {
    img: '5',
    title: 'Synthwave Modern Painting',
    avatar_img: '6',
    avatar_name: 'makinzi_beck',
    price: '3,003',
  },
];
// Random component
const Completionist = () => <span>auction ending soon now!</span>;
// Renderer callback with condition
const renderer = ({hours, minutes, seconds, completed}) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        {hours} : {minutes} : {seconds}
      </span>
    );
  }
};
function Hero3() {
  const ref = useRef();
  const closeTooltip = () => ref.current.close();

  const {marketPlaceNFTs, loadingState} = useMarketPlaceContext();
  const nfts = marketPlaceNFTs;

  return (
    <div>
      <div className="hero__3">
        <div className="container">
          <div className="row align-items-center mb-50 md:space-y-20">
            <div className="col-lg-6">
              <h1 className="hero__title">
                <span className="color_brand">Discover</span> rare digital art
                and collect NFTs
              </h1>
            </div>
            <div className="col-lg-6">
              <p className="hero__text color_black">
                TheTradingBloc is a shared liquidity NFT market smart contract which is
                used by multiple websites to provide the users the best possible
                experience.
              </p>
            </div>
          </div>
          <div className="wrapper">
            <div className="row">
              {nfts.map((val, i) => (
                <div className="col-lg-4" key={i}>
                  <div className="card__item two">
                    <div className="card_body space-y-10">
                      {/* ???? =============== */}
                      <div className="card_head">
                        <img src={val.image} alt="herr" />
                        <div className="block_timer">
                          <div
                            className="d-flex justify-content-center
                                                align-items-center txt_sm _bold box_counter">
                            <Countdown
                              date={Date.now() + 60000000}
                              renderer={renderer}
                            />
                          </div>
                        </div>
                        <div
                          className="details d-flex
                                                justify-content-between">
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{width: '80%'}}
                              aria-valuenow={80}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                      </div>
                      {/* ???? =============== */}
                      <h6 className="card_title">
                        <Link className="color_black" to="item-details">
                          {val.name}
                        </Link>
                      </h6>
                      <div className="hr" />
                      <div className="card_footer justify-content-between">
                        {/* <Link
                          to="profile"
                          className="creators
                                                space-x-10">
                          <div className="avatars -space-x-20">
                            <img
                              src={`img/avatars/avatar_${val.avatar_img1}.png`}
                              alt="Avatar"
                              className="avatar avatar-sm"
                            />
                            <img
                              src={`img/avatars/avatar_${val.avatar_img2}.png`}
                              alt="Avatar"
                              className="avatar avatar-sm"
                            />
                          </div>
                          <p className="avatars_name txt_sm">
                            @{val.name}...
                          </p>
                        </Link> */}
                        <div className="space-x-3">
                          <p className="color_green txt_sm">{val.price} ETH</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero3;
