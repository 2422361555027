import React, {useRef, useState} from 'react';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import {Link} from 'react-router-dom';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Countdown from 'react-countdown';
import useDocumentTitle from '../../../components/useDocumentTitle';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useLocation } from 'react-router-dom';


// Random component
const Completionist = () => <span>auction ending soon now!</span>;
// Renderer callback with condition
const renderer = ({hours, minutes, seconds, completed}) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        {hours} : {minutes} : {seconds}
      </span>
    );
  }
};
const ItemDetails = () => {
  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  const [isShare, setShare] = useState(false);

  const location = useLocation();
  const NFTDetail = location.state;
  

  const toggleShare = () => {
    setShare(!isShare);
  };
  const [isMore, setMore] = useState(false);

  const toggleMore = () => {
    setMore(!isMore);
  };

  useDocumentTitle('Item Details');
  return (
    <div>
      <Header />
      <div className="container">
        <Link to="/" className="btn btn-white btn-sm my-40">
          Back to home
        </Link>
        <div className="item_details">
          <div className="row sm:space-y-20">
            <div className="col-lg-6">
              <img
                className="item_img"
                src={NFTDetail?.image}
                alt="ImgPreview"
              />
            </div>
            <div className="col-lg-6">
              <div className="space-y-20">
                <h3>{NFTDetail?.name}</h3>
                <div className="d-flex justify-content-between">
                  <div className="space-x-10 d-flex align-items-center">
                    <p>1 of 1</p>
                    <Link to="#" className="likes space-x-3">
                      <i className="ri-heart-3-fill" />
                      <span className="txt_sm">2.1k</span>
                    </Link>
                  </div>
                  <div className="space-x-10 d-flex align-items-center">
                    <div>
                      <div className="share">
                        <div className="icon" onClick={toggleShare}>
                          <i className="ri-share-line"></i>
                        </div>
                        <div
                          className={`dropdown__popup ${
                            isShare ? 'visible' : null
                          } `}>
                         <ul className="space-y-10">
                            <li>
                            <a href="https://www.facebook.com/" rel="noreferrer"  target="_blank">
                                <i className="ri-facebook-line"></i>
                              </a>
                            </li>
                            <li>
                            <a href="https://www.messenger.com/" rel="noreferrer"  target="_blank">
                                <i className="ri-messenger-line"></i>
                              </a>
                            </li>
                            <li>
                            <a href="https://whatsapp.com" target="_blank" rel="noreferrer" >
                                <i className="ri-whatsapp-line"></i>
                              </a>
                            </li>
                            <li>
                            <a href="https://youtube.com" target="_blank" rel="noreferrer" >
                                <i className="ri-youtube-line"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="more">
                        <div className="icon" onClick={toggleMore}>
                          <i className="ri-more-fill"></i>
                        </div>
                        <div
                          className={`dropdown__popup ${
                            isMore ? 'visible' : null
                          } `}>
                          <ul className="space-y-10">
                            <li>
                               
                                  <Link
                                    to="report"
                                    className="content space-x-10 d-flex">
                                    <i className="ri-flag-line" />
                                    <span> Report </span>
                                  </Link>
                                 
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <select className="select_custom btn btn-dark btn-sm">
                  <option value="A">View proof of authenticity </option>
                  <option value="B">View on IPFS</option>
                  <option value="C">View on Etherscan</option>
                </select>

                <div className="box">
                  <Tabs className="space-y-20">
                    <div className="d-flex justify-content-between mb-30_reset">
                      <TabList className="d-flex space-x-10 mb-30 nav-tabs">
                        <Tab className="nav-item">
                          {/* <Link
                            className="btn btn-white btn-sm"
                            data-toggle="tab"
                            to="#tabs-1"
                            role="tab">
                            Details
                          </Link> */}
                         Details
                        </Tab>
                        {/* <Tab>
                          <Link
                            className="btn btn-white btn-sm"
                            data-toggle="tab"
                            to="#tabs-2"
                            role="tab">
                            Bids
                          </Link>
                        </Tab>
                        <Tab>
                          <Link
                            className="btn btn-white btn-sm"
                            data-toggle="tab"
                            to="#tabs-3"
                            role="tab">
                            History
                          </Link>
                        </Tab> */}
                      </TabList>

                    </div>
                    <div className="hr" />
                    <div className="tab-content">
                      <TabPanel className="active">
                        <p>
                          {NFTDetail?.description}
                        </p>
                      </TabPanel>
                      {/* <TabPanel>
                        <p>No active bids yet. Be the first to make a bid!</p>
                      </TabPanel> */}
                      {/* <TabPanel>
                        <div className="space-y-20">
                          <div className="creator_item creator_card space-x-10">
                            <div className="avatars space-x-10">
                              <div className="media">
                                <div className="badge">
                                  <img
                                    src="/img/icons/Badge.svg"
                                    alt="ImgPreview"
                                  />
                                </div>
                                <Link to="profile">
                                  <img
                                    src="img/avatars/avatar_1.png"
                                    alt="Avatar"
                                    className="avatar avatar-md"
                                  />
                                </Link>
                              </div>
                              <div>
                                <p className="color_black">
                                  Bid accepted
                                  <span className="color_brand">1 ETH</span> by
                                  <Link
                                    className="color_black txt _bold"
                                    to="profile">
                                    ayoub
                                  </Link>
                                </p>
                                <span className="date color_text">
                                  28/06/2021, 12:08
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="creator_item creator_card space-x-10">
                            <div className="avatars space-x-10">
                              <div className="media">
                                <div className="badge">
                                  <img
                                    src="/img/icons/Badge.svg"
                                    alt="ImgPreview"
                                  />
                                </div>
                                <Link to="profile">
                                  <img
                                    src="img/avatars/avatar_2.png"
                                    alt="Avatar"
                                    className="avatar avatar-md"
                                  />
                                </Link>
                              </div>
                              <div>
                                <p className="color_black">
                                  Bid accepted
                                  <span className="color_brand">3 ETH</span> by
                                  <Link
                                    className="color_black txt _bold"
                                    to="profile">
                                    monir
                                  </Link>
                                </p>
                                <span className="date color_text">
                                  22/05/2021, 12:08
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel> */}
                    </div>
                  </Tabs>
                </div>
                <div className="numbers">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="space-y-5">
                        <p className="color_text">Price</p>
                        <h4>
                          {NFTDetail?.price} ETH
                          {/* <span className="txt_sm color_text">
                            ETH/ $4769.88
                          </span> */}
                        </h4>
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                      <div className="space-y-5">
                        <p className="color_text">countdown</p>
                        <div className="txt_lg _bold">
                          <Countdown
                            date={Date.now() + 80000000}
                            renderer={renderer}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="hr2" />
                <div className="creators">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="avatars space-x-5">
                        <div className="media">
                          <Link to="/Profile"  >
                            <img
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAAAXNSR0IArs4c6QAAA65JREFUeF7t3b11FTEQhmHdMhxzKIAG3ACEOCdxBe7EKYlzE+IeyKiAmDJMEU8wzNn35lpJ37yaH61W9/b26+P7Gfx9+vZIvf9++U7ttfH28d8CwBAIANPvbBdw+/jzABcHOAACoCRQGCgEiHrnlAMMVzGFgIsDHAABUA4gDJQDiHrlAGd6J7MQcHGAAyAAygGEgXIAUa8coBxg+wraPv7b89M9nQf48PCXfIAK+OPzT+pfG399+0KP0Crgz+sd9R8AJN85AZAHIITyAHgkrBBQCKAVqI0LAYUAYqgQUAgggKoCKgMJoMpAkq8y8LQR1EYQraF2Au3LpnKAcgBagOUAJF85QDlAL4N6GyhOpI2gNoKEn8NJoH4ePp3F6148qX/O0ZdROn71IHwoNADsQEoAYBmnAuYB8IqYPEAegBbRdAylwZcD+OfdATD7LqEkEF3ANMBVAbgTh/avDCwJLAmkRTTtQmnwJYElgdMAlwOUA5ATqwog+XoXwNe8TbtQtH9VQFVAVQAtojzA8p1Asv45fKBBj6VfffycBF5dQD2RMw1wACDBAXBxAQMgAEiBQgBecjQtYB6A+K8KmAa4JPDiAAdAAMzeFbw9hm4ffx4gD5AHEAbyAKJe7wL483qU/xQCUME8wMUFDIAAIAUuvxFE6tWYFRjPAXgGPYAUCACSb3/jANhvQ5pBAJB8+xsHwH4b0gwCgOTb3zgA9tuQZhAAJN/+xgGw34Y0gwAg+fY3DoD9NqQZBADJt79xAOy3Ic0gAEi+/Y0DYL8NaQYMgN4Qordc6YkcUu8cPtM3rV8AIAF6oicAXmb/Nw/tnweYJrgQYAuoEIAuoBCA//pVEvhICKp+eQCSvyqAbwpVgssBygFwDVvzcoByACJouooqByDzlQOUAzzM/nm25lB5gDyA3RAyHcOqAqoCcA1b86qAqgAiaNqD3p6f7t9pBjVerUAArDafDz4AXMPVTwiA1ebzwQeAa7j6CQGw2nw++ABwDVc/IQBWm88HHwCu4eonBMBq8/ngA8A1XP2EAFhtPh98ALiGq58QAKvN54MPANdw9RMCYLX5fPAMgJ6I0SNd2r9KOD1+7T8AkAA1gAKs/QdAANiRsGmCtX+0/9EVqOPX/vMASIAaIABe78gEKiB1/h/88aUCmAdAAtQACrD2HwABUBIoDOgKzAOUAwh/XIUUAkj+wwbIA+QBCEENQXkAkj8PwFelKsHqQtH+hQA1QADYFTOqXyEAXYAaYHoB/QN3Oz9txZiQNwAAAABJRU5ErkJggg=="
                              alt="Avatar"
                              className="avatar avatar-sm"
                            />
                          </Link>
                        </div>
                        <div>
                          <a rel="noreferrer" target="_blank" href={'https://rinkeby.etherscan.io/address/'+NFTDetail?.seller}>
                            <p className="avatars_name color_black">
                              {NFTDetail?.seller}
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                      <div className="avatars space-x-5">
                        <div className="media">
                          <div className="badge">
                            <img
                              className="badge"
                              src="../img/icons/Badge.svg"
                              alt="ImgPreview"
                            />
                          </div>
                          <Link to="profile">
                            <img
                              src="/assets/img/avatars/avatar_2.png"
                              alt="Avatar"
                              className="avatar avatar-sm"
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to="profile">
                            <p className="avatars_name color_black">
                              @makinzi_jamy...
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="d-flex space-x-20">
                  
                <Link
                              to="place-order" state={NFTDetail}
                              className="btn btn-primary
                                                      w-full"
                              aria-label="Close">
                              Place Order
                            </Link>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ItemDetails;
