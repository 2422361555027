import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { useMarketPlaceContext } from '../../context/MarketPlaceContext';

const SidebarProfile = () => {
  const auth = useAuth();
  const userInfo = auth.userInfo;

  const marketplaceContext = useMarketPlaceContext();

  return (
    <div className="profile__sidebar">
      <div className="space-y-40">
        <div className="space-y-10">
          <h5>About me</h5>
          <div className="box space-y-20">
            <p>
              {userInfo.bio}
            </p>
            <div className="row">
              <div className="col-6">
                <span className="txt_sm color_text">Collections</span>
                <h4>0</h4>
              </div>
              <div className="col-6">
                <span className="txt_sm color_text">Creations</span>
                <h4>{marketplaceContext.listedItemCount}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-10">
          <h5>Follow me</h5>
          <div className="box">
            <ul className="social_profile space-y-10 overflow-hidden">
              <li>
              <a href={'https://www.facebook.com/'+userInfo.facebookUserName} rel="noreferrer"  target="_blank">
                  <i className="ri-facebook-fill" />
                  <span className="color_text">facebook/</span>
                  {userInfo.facebookUserName}
                </a>
              </li>
              <li>
              <a href={'https://www.discord.com/'+userInfo.discordUserName} rel="noreferrer"  target="_blank">
                  <i className="ri-discord-line" />
                  <span className="color_text"> discord/</span>
                  {userInfo.discordUserName}
                </a>
              </li>
              <li>
              <a href="https://twitter.com" target="_blank" rel="noreferrer" >
                  <i className="ri-twitter-line" />
                  <span className="color_text"> twitter/</span>
                  {userInfo.twitterUserName}
                </a>
              </li>
              <li>
              <a href="https://youtube.com" target="_blank" rel="noreferrer" >
                  <i className="ri-youtube-fill" />
                  <span className="color_text">youtube/{userInfo.youTubeChannleName}</span>
                  
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default SidebarProfile;
