import React, {useRef, useState, useEffect} from 'react';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import HeroEditProfile from '../../../components/hero/HeroEditProfile';
import useDocumentTitle from '../../../components/useDocumentTitle';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {Link, useNavigate} from 'react-router-dom';

import { useAuth } from '../../../context/AuthContext';

const UpdateProfile = () => {
  useDocumentTitle(' Update Profile');
  //const deleting = () => toast.error('Avatar delete in progress');
  const confirm = () => toast.success('Your email verified');
  //const update = () => toast.success('Your Profile updated');

 
  const [formInput, updateFormInput] = useState({ displayUserName: '', email: '', bio: '',facebookUserName: '', twitterUserName: '', discordUserName: '', imageURL : '' })
  const [userInfo, setUserInfo] = useState({});
  const ref = useRef();
  const auth = useAuth();
  const closeTooltip = () => ref.current.close();
  const navigate = useNavigate();
  
  const getProfile = async() =>{
    
    
  };

  const updateProfile = async () =>{
   
  }

  useEffect(()=>{
    getProfile();
  },[])

  return (
    <div className="edit_profile">
      <Header />
      <HeroEditProfile />
      <div className="container">
        <div>
        <ToastContainer position="bottom-right" />
          <div className="box edit_box col-lg-9 space-y-30">
            <div className="row sm:space-y-20">
              <div className="col-lg-6 account-info">
                <h3 className="mb-20">Account info </h3>
                <div className="form-group space-y-10 mb-0">
                  <div className="space-y-40">
                    <div className="space-y-10">
                      <span className="nameInput">Display name</span>
                      <input
                        id="txtUsername"
                        type="text"
                        onChange={e => updateFormInput({ ...formInput, displayUserName: e.target.value })}
                        className="form-control"
                        value={formInput.displayUserName || ''}
                        
                        placeholder='e.g. John'
                      />
                    </div>
                    <div className="space-y-10">
                      <span className="nameInput d-flex justify-content-between">
                        Email
                        <span className="txt_xs">
                          Your email for marketplace notifications
                        </span>
                      </span>
                      <div className="confirm">
                        <input
                          id="txtEmail"
                          type="text"
                          name = "email"
                          className="form-control"
                          onChange={e => updateFormInput({ ...formInput, email: e.target.value })}
                          placeholder="Enter your email"
                          value={formInput.email || ''}
                        />
                        <Link
                          to="#"
                          className="confirm-btn btn btn-dark btn-sm"
                          onClick={confirm}>
                          Confirm
                        </Link>
                      </div>
                    </div>
                   
                    <div className="space-y-10">
                      <span className="nameInput">Bio</span>
                      <textarea
                        style={{minHeight: 190}}
                        onChange={e => updateFormInput({ ...formInput, bio: e.target.value })}
                        placeholder="Add your bio"
                        value={formInput.bio || ''}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 social-media">
                <h3 className="mb-20">Your Social media</h3>
                <div className="form-group space-y-10">
                  <div className="space-y-40">
                    <div className="d-flex flex-column">
                      <span className="nameInput mb-10">Facebook</span>
                      <input
                        type="text"
                        className="form-control"
                        onChange={e => updateFormInput({ ...formInput, facebookUserName: e.target.value })}
                        placeholder="facebook username"
                        value={formInput.facebookUserName || ''}
                      />
                      
                    </div>
                    <div className="d-flex flex-column">
                      <span className="nameInput mb-10">Twitter</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="twitter username"
                        value={formInput.twitterUserName || ''}
                        onChange={e => updateFormInput({ ...formInput, twitterUserName: e.target.value })}
                      />
                      
                    </div>
                    <div className="d-flex flex-column">
                      <span className="nameInput mb-10">Discord</span>
                      <input
                        type="text"
                        className="form-control"
                        onChange={e => updateFormInput({ ...formInput, discordUserName: e.target.value })}
                        placeholder="discord username"
                        value={formInput.discordUserName || ''} 
                      />
                      
                    </div>

                    <div className="d-flex flex-column">
                      <span className="nameInput mb-10">YouTube</span>
                      <input
                        type="text"
                        className="form-control"
                        onChange={e => updateFormInput({ ...formInput, youTubeChannleName: e.target.value })}
                        placeholder="YouTube Channel Name"
                        value={formInput.youTubeChannleName || ''} 
                      />
                      
                    </div>
                  </div>
                </div>

                
              </div>
            </div>
            <div className="hr" />
            <p className="color_black">
              To update your settings you should sign message through your
              wallet. Click 'Update profile' then sign the message.
            </p>
            <div>
              <Link to="#" className="btn btn-grad" onClick={updateProfile}>
                Update Profile
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UpdateProfile;
