import React from 'react';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import HeroPrivacy from '../../../components/hero/HeroPrivacy';
import useDocumentTitle from '../../../components/useDocumentTitle';

const PrivacyPolicy = () => {
  useDocumentTitle(' Privacy Policy');
  return (
    <div>
      <Header />
      <HeroPrivacy />
      <div className="privacy__page">
        <div className="container">
          <div className="box space-y-30" style={{padding: '60px 30px'}}>
            <div className="space-y-20">
              <h6>Last Updated: May 12, 2022</h6>
              <p>
              This Privacy Policy is designed to help you understand how BlocUnited LLC (called “BlocUnited”, “we,” “us,” and “our”) collects, uses, and shares your personal information and to help you understand and exercise your privacy rights.
              </p>

              <p>
                <ol>
                  <li className='custom-order-list-number'>Scope</li>
                  <li className='custom-order-list-number'>Changes To Our Privacy Policy</li>
                  <li className='custom-order-list-number'>Personal Information We Collect</li>
                  <li className='custom-order-list-number'>How We Use Your Information</li>
                  <li className='custom-order-list-number'>How We Disclose Your Information</li>
                  <li className='custom-order-list-number'>Your Privacy Choices And Rights</li>
                  <li className='custom-order-list-number'>Security Of Your Information</li>
                  <li className='custom-order-list-number'>International Data Transfers</li>
                  <li className='custom-order-list-number'>Retention Of Personal Information</li>
                  <li className='custom-order-list-number'>Children’s Information</li>
                  <li className='custom-order-list-number'>Third-Party Websites/Applications</li>
                  <li className='custom-order-list-number'>Supervisory Authority</li>
                  <li className='custom-order-list-number'>Contact Us</li>
                </ol>
              </p>

              <h4>1. Scope</h4>
              <p>
              This Privacy Policy applies to personal information processed by us, including on our Websites located at https://www.thetradingbloc.com  (the “Services”). To make this Privacy Policy easier to read, our Services and the content and functionalities accessible via our Services are collectively referred to as the “Services.” For the avoidance of doubt, this Privacy Policy does not apply to the decentralized digital ecosystems developed by BlocUnited (the “Protocol”) and BlocUnited cannot control activity and data on the Protocol, the validation of transactions on the Protocol, or use of the Protocol. 
              </p>

              <h4>2. CHANGES TO OUR PRIVACY POLICY</h4>
              <p>
              We may revise this Privacy Policy from time to time at our sole discretion. If there are any material changes to this Privacy Policy, we will notify you as required by applicable law. You understand and agree that you will be deemed to have accepted the updated Privacy Policy if you continue to use our Services after the new Privacy Policy takes effect.
              </p>

              <h4>3. PERSONAL INFORMATION WE COLLECT</h4>
              <p>
              The categories of personal information we collect depend on how you interact with us, our Services, and the requirements of applicable law. We collect information that you provide to us, information we obtain automatically when you use our Services, and information from other sources such as third-party services and organizations, as described below.
              </p>
              <h5 >(A) INFORMATION YOU PROVIDE TO US DIRECTLY</h5>
              <p>
              We may collect the following personal information that you provide to us:
              <ol className='custom-order-list'>
                <li className='custom-order-list'>
                Account Creation. We may collect information if you create an account with us, such as your name, username, email address, or password. 
                </li>
                <li className='custom-order-list'>
                Purchases, Wallet and Transaction Information. In order to engage in transactions on our Services, you may need to provide us or our third-party payment processors with access to or information about your digital wallet. We will never ask you or collect your private keys. Other Transactions. (We do not directly collect or store any payment card information entered through our Services or access your digital wallet, but we may receive from third-party payment processing providers information associated with your payment card information (e.g., your billing details)
                </li>
                <li className='custom-order-list'>
                We may collect personal information and details associated with your activities with our Services.
                </li>
                <li className='custom-order-list'>
                Your Communications with Us. We may collect personal information, such as email address, phone number or mailing address when you request information about our Services, register for our newsletter, apply for a job or otherwise communicate with us.
                </li>
                <li className='custom-order-list'>
                Interactive Features. We and others who use our Services may collect personal information that you submit or make available through our interactive features (e.g., via BlocUnited communities, messaging and chat features, commenting functionalities, forums, blogs and social media pages). Any personal information you provide on the public sections of these features will be considered “public” (the “User Content”) unless otherwise required by applicable law, and is not subject to the privacy protections referenced herein. Please exercise caution before revealing any information that may identify you in the real world to other users.
                </li>
                <li className='custom-order-list'>
                Surveys. We may contact you to participate in surveys. If you decide to participate, you may be asked to provide certain information that may include personal information.
                </li>
                <li className='custom-order-list'>
                Sweepstakes, Giveaways, or Contests. We may collect personal information you provide for any sweepstakes, giveaways, or contests that we offer. In some jurisdictions, we are required to publicly share information of sweepstakes and contest winners.
                </li>
                <li className='custom-order-list'>
                Conferences, Trade Shows, and Other Events. We may collect personal information from individuals when we attend or host conferences, trade shows, and other events.
                </li>
                <li className='custom-order-list'>
                Business Development and Strategic Partnerships. We may collect personal information from individuals and third parties to assess and pursue potential business opportunities.
                </li>
                <li className='custom-order-list'>
                Job Applications. We may post job openings and opportunities with our Services. If you reply to one of these postings by submitting your application, CV, and/or cover letter to us, we will collect and use this information to assess your qualifications.
                </li>
              </ol>
              </p>
              <h5 >(A) INFORMATION YOU PROVIDE TO US DIRECTLY</h5>
              <p>
              We may collect personal information automatically when you use our Services:
              <ol>
              <li className='custom-order-list'>
              Automatic Data Collection. We may collect certain information automatically when you use our Services, such as your Internet protocol (IP) address, user settings, MAC address, cookie identifiers, mobile carrier, mobile advertising and other unique identifiers, browser or device information, location information (including approximate location derived from IP address), Internet service provider, and metadata about the content you provide. We may also automatically collect information regarding your use of our Services, such as pages that you visit before, during, and after using our Services, information about the links you click, the types of content you interact with, the frequency and duration of your activities, and other information about how you use our Services.
              </li>
              <li className='custom-order-list'>
              Cookie Policy for Cookies, Pixel Tags/Web Beacons, and Other Technologies. We, as well as third parties that provide content, advertising, or other functionality with our Services, may use cookies, pixel tags, local storage, and other technologies (“Technologies”) to automatically collect information through your use of our Services.
              </li>
              <li className='custom-order-list'>
              Pixel Tags/Web Beacons and Other Technologies. A pixel tag (also known as a web beacon) is a piece of code embedded in our Services that collects information about engagement with our Services. The use of a pixel tag allows us to record, for example, that a user has visited a particular web page or clicked on a particular advertisement. We may also include web beacons in emails to understand whether messages have been opened, acted on, or forwarded.
              <br/>
              <br/>
              <b>Our uses of these Technologies fall into the following general categories:</b>
              <br/>
              <br/>
              </li>
              <li className='custom-order-list'>
              Operationally Necessary. This includes Technologies that allow you access to our Services, applications, and tools that are required to identify irregular website behavior, prevent fraudulent activity, improve security, or allow you to make use of our functionality;
              </li>
              <li className='custom-order-list'>
              Performance-Related. We may use Technologies to assess the performance of our Services, including as part of our analytic practices to help us understand how individuals use our Services (see Analytics below);
              </li>
              <li className='custom-order-list'>
              Functionality-Related. We may use Technologies that allow us to offer you enhanced functionality when accessing or using our Services. This may include identifying you when you sign into our Services or keeping track of your specified preferences, interests, or past items viewed;
              </li>
              <li className='custom-order-list'>
              Advertising- or Targeting-Related. We may use first-party or third-party Technologies to deliver content, including ads relevant to your interests, with our Services or on third-party website.
              <br/>
              <br/>
              <b>See “Your Privacy Choices and Rights” below to understand your choices regarding these Technologies.</b>
              <br/>
              <br/>
              </li>
              <li className='custom-order-list'>
              Analytics. We may use our Technologies and other third-party tools to process analytics information with our Services. These technologies allow us to process usage data to better understand how our website and web-related services are used, and to continually improve and personalize our Services. Some of our analytics partners include:
              </li>
              <li className='custom-order-list'>
              Google Analytics. For more information about how Google uses your data (including for its own purposes, e.g., for profiling or linking it to other data), please visit Google Analytics Privacy Policy.
              </li>
              <li className='custom-order-list'>
              Intercom. For more information about how Intercom uses your data for customer interaction purposes, please visit Intercom's Privacy Policy.
              </li>
              <li className='custom-order-list'>
              Mixpanel. For more information about how Mixpanel uses your data for customer interaction purposes, please visit Mixpanel's Privacy Policy.
              </li>
              <li className='custom-order-list'>
              Segment. For more information about how Segment uses your data for customer interaction purposes, please visit Segment's Privacy Policy.
              </li>
              <li className='custom-order-list'>
              Social Media Platforms. Our Services may contain social media buttons, such as Discord, Snapchat, Twitter, and Telegram that might include widgets such as the “share this” button or other interactive mini programs). These features may collect your IP address and which page you are visiting with our Services, and may set a cookie to enable the feature to function properly. Your interactions with these platforms are governed by the privacy policy of the company providing it.
              </li>
              </ol>
              </p>

              <h4>(C) INFORMATION COLLECTED FROM OTHER SOURCES</h4>
              <p>
              <ol>
              <li className='custom-order-list'>
              Third-Party Sources. We may obtain information about you from other sources, including through third-party services and organizations. For example, if you access our Services through a third-party application, such as an app store, a third-party login service, or a social networking Services, we may collect information about you from that third-party application that you have made available via your privacy settings.
              </li>
              <li className='custom-order-list'>
              Referrals, Sharing, and Other Features. Our Services may offer various tools and functionalities that allow you to provide information about your friends through our referral service; third parties may also use these services to upload information about you. Our referral services may also allow you to forward or share certain content with a friend or colleague, such as an email inviting your friend to use our Services. Please only share with us contact information of people with whom you have a relationship (e.g., relative, friend, neighbor, or co-worker).
              </li>
               </ol>
              </p>

              <h4>4. HOW WE USE YOUR INFORMATION</h4>
              <p>
              We use your information for a variety of business purposes, including to provide our Services, for administrative purposes, and to market our products and services, as described below.
              </p>
              <h4>(A) PROVIDE OUR SERVICES</h4>
              <p>
              We use your information to fulfill our contract with you and provide you with our Services and perform our contract with you, such as:
              <ol>
              <li className='custom-order-list'>
              Managing your information and accounts;
              </li>
              <li className='custom-order-list'>
              Providing access to certain areas, functionalities, and features of our Services;
              </li>
              <li className='custom-order-list'>
              Answering requests for customer or technical support;
              </li>
              <li className='custom-order-list'>
              Communicating with you about your account, activities with our Services, and policy changes;
              </li>
              <li className='custom-order-list'>
              Processing information about your wallet to facilitate transfers via our Services;
              </li>
              <li className='custom-order-list'>
              Processing applications if you apply for a job we post with our Services; and
              </li>
              <li className='custom-order-list'>
              Allowing you to register for events.
              </li>
               </ol>
              </p>
              
              <h4>(B) ADMINISTRATIVE PURPOSES</h4>
              <p>
              We use your information for our legitimate interest, such as:
              <ol>
              <li className='custom-order-list'>
              Pursuing our legitimate interests such as direct marketing, research and development (including marketing research), network and information security, and fraud prevention;
              </li>
              <li className='custom-order-list'>
              Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity;
              </li>
              <li className='custom-order-list'>
              Measuring interest and engagement in our Services;
              </li>
              <li className='custom-order-list'>
              Improving, upgrading, or enhancing our Services;
              </li>
              <li className='custom-order-list'>
              Developing new products and Services;
              </li>
              <li className='custom-order-list'>
              Ensuring internal quality control and safety;
              </li>
              <li className='custom-order-list'>
              Authenticating and verifying individual identities;
              </li>
              <li className='custom-order-list'>
              Debugging to identify and repair errors with our Services;
              </li>
              <li className='custom-order-list'>
              Auditing relating to interactions, transfers, and other compliance activities; 
              </li>
              <li className='custom-order-list'>
              Sharing information with third parties as needed to provide our Services;
              </li>
              <li className='custom-order-list'>
              Enforcing our agreements and policies; and
              </li>
              <li className='custom-order-list'>
              Other uses as required to comply with our legal obligations.
              </li>
               </ol>
              </p>

              <h4>(C) MARKETING AND ADVERTISING OUR PRODUCTS AND SERVICES</h4>
              <p>
              We may use personal information to tailor and provide you with content and advertisements. We may provide you with these materials as permitted by applicable law. Some of the ways we may market to you include email campaigns, custom audiences advertising, and “interest-based” or “personalized advertising,” including through cross-device tracking.
              </p>
              <p>
              If you have any questions about our marketing practices or if you would like to opt out of the use of your personal information for marketing purposes, you may contact us at any time as set forth in “Contact Us” below.
              </p>

              <h4>(D) WITH YOUR CONSENT</h4>
              <p>
              We may use personal information for other purposes that are clearly disclosed to you at the time you provide personal information or with your consent.
              </p>

              <h4>(E) OTHER PURPOSES</h4>
              <p>
              We also use your information for other purposes as requested by you or as permitted by applicable law.
              <ol>
              <li className='custom-order-list'>
              Automated Decision-Making. We may engage in automated decision-making, including profiling. BlocUnited's processing of your personal information will not result in a decision based solely on automated processing that significantly affects you unless such a decision is necessary as part of a contract we have with you, we have your consent, or we are permitted by law to engage in such automated-decision making. If you have questions about our automated decision-making, you may contact us as set forth in “Contact Us” below.
              </li>
              <li className='custom-order-list'>
              De-identified and Aggregated Information. We may use personal information and other information about you to create de-identified and/or aggregated information, such as de-identified demographic information, de-identified location information, information about the device from which you access our Services, or other analyses we create.
              </li>
              </ol>
              </p>

              <h4>5. HOW WE DISCLOSE YOUR INFORMATION </h4>
              <p>
              We disclose your information to third parties for a variety of business purposes, including to provide our Services, to protect us or others, or in the event of a major business transaction such as a merger, sale, or asset transfer, as described below.
              </p>
              <h4>(A) DISCLOSURES TO PROVIDE OUR SERVICES</h4>
              <p>
              The categories of third parties with whom we may share your information are described below.
              <ol>
              <li className='custom-order-list'>
              Notice Regarding Use of Blockchain. Transactions involving Ape Coins will be conducted via the Ethereum blockchain. Information about your transfers will be provided to the blockchain and may be accessible to third parties due to the public nature of the blockchain. Because entries to the blockchain are, by their nature, public, and because it may be possible for someone to identify you through your pseudonymous, public wallet address using external sources of information, any transaction you enter onto the blockchain could possibly be used to identify you or information about you.
              </li>
              <li className='custom-order-list'>
              Other Users of our Services and Parties You Transact With. Some of your personal information may be visible to other users of our Services (e.g., information featured on generally accessible parts of your profile). In addition, to complete transfers via our Services, we will need to share some of your personal information with the party that you are transacting with.
              </li>
              <li className='custom-order-list'>
              Third-Party Websites and Applications. You may choose to share personal information or interact with third-party Websites and/or third-party applications, including, but not limited to, third-party electronic wallet extensions. Once your personal information has been shared with a third-party website or a third-party application, it will also be subject to such third-party’s privacy policy. We encourage you to closely read each third-party Websites or third-party application privacy policy before sharing your personal information or otherwise interacting with them. Please note that we do not control, and we are not responsible for the third-party website’s or the third-party application’s processing of your personal information.
              </li>
              <li className='custom-order-list'>
              Service Providers. We may share your personal information with our third-party service providers who use that information to help us provide our Services. This includes service providers that provide us with IT support, hosting, customer service, and related services.
              </li>
              <li className='custom-order-list'>
              Business Partners. We may share your personal information with business partners to provide you with a product or service you have requested. We may also share your personal information to business partners with whom we jointly offer products or services.
              </li>
              <li className='custom-order-list'>
              Affiliates. We may share your personal information with members of our corporate family.
              </li>
              <li className='custom-order-list'>
              Advertising Partners. We may share your personal information with third-party advertising partners. These third-party advertising partners may set Technologies and other tracking tools with our Services to collect information regarding your activities and your device (e.g., your IP address, cookie identifiers, page(s) visited, location, time of day). These advertising partners may use this information (and similar information collected from other services) for purposes of delivering personalized advertisements to you when you visit digital properties within their networks. This practice is commonly referred to as “interest-based advertising” or “personalized advertising.”
              </li>
              <li className='custom-order-list'>
              APIs/SDKs. We may use third-party application program interfaces (“APIs”) and software development kits (“SDKs”) as part of the functionality of our Services. For more information about our use of APIs and SDKs, please contact us as set forth in “Contact Us” below.
              </li>
              </ol>
              </p>

              <h4>(B) DISCLOSURES TO PROTECT US OR OTHERS</h4>
              <p>
              We may access, preserve, and disclose any information we store associated with you to external parties if we, in good faith, believe doing so is required or appropriate to: comply with law enforcement or national security requests and legal process, such as a court order or subpoena; protect your, our, or others’ rights, property, or safety; enforce our policies or contracts; collect amounts owed to us; or assist with an investigation or prosecution of suspected or actual illegal activity.
              </p>

              <h4>(C) DISCLOSURE IN THE EVENT OF MERGER, SALE, OR OTHER ASSET TRANSFERS</h4>
              <p>
              If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service to another provider, your information may be sold or transferred as part of such a transaction, as permitted by law and/or contract.
              </p>

              <h4>6. YOUR PRIVACY CHOICES AND RIGHTS</h4>
              <p>
              Your Privacy Choices. The privacy choices you may have about your personal information are determined by applicable law and are described below.
              <ol>
              <li className='custom-order-list'>
              Email Communications. If you receive an unwanted email from us, you can use the unsubscribe link found at the bottom of the email to opt out of receiving future emails. Note that you will continue to receive transfer-related emails regarding services you have requested. We may also send you certain non-promotional communications regarding us and our Services, and you will not be able to opt out of those communications (e.g., communications regarding our Services or updates to our Terms of Service or this Privacy Policy).
              </li>
              <li className='custom-order-list'>
              Text Messages. You may opt out of receiving text messages from us by following the instructions in the text message you have received from us or by otherwise contacting us.
              </li>
              <li className='custom-order-list'>
              Mobile Devices. We may send you push notifications through our mobile application. You may opt out from receiving these push notifications by changing the settings on your mobile device. With your consent, we may also collect precise location-based information via our mobile application. You may opt out of this collection by changing the settings on your mobile device.
              </li>
              <li className='custom-order-list'>
              “Do Not Track.” Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers. 
              </li>
              <li className='custom-order-list'>
              Cookies and Interest-Based Advertising. You may stop or restrict the placement of Technologies on your device or remove them by adjusting your preferences as your browser or device permits. However, if you adjust your preferences, our Services may not work properly. Please note that cookie-based opt-outs are not effective on mobile applications. However, you may opt-out of personalized advertisements on some mobile applications by following the instructions for Android, iOS, and others. Please note you must separately opt out in each browser and on each device.
              <br/><br/>
              Your Privacy Rights. In accordance with applicable law, you may have the right to:
              <br/><br/>
              </li>
              <li className='custom-order-list'>
              Access Personal Information about you, including: (i) confirming whether we are processing your personal information; (ii) obtaining access to or a copy of your personal information; or (iii) receiving an electronic copy of personal information that you have provided to us, or asking us to send that information to another company (aka the right of data portability);
              </li>
              <li className='custom-order-list'>
              Request Correction of your personal information where it is inaccurate or incomplete. In some cases, we may provide self-service tools that enable you to update your personal information;
              </li>
              <li className='custom-order-list'>
              Request Deletion of your personal information;
              </li>
              <li className='custom-order-list'>
              Request Restriction of or Object to our processing of your personal information, including where the processing of your personal information is based on our legitimate interest or for direct marketing purposes; and
              </li>
              <li className='custom-order-list'>
              Withdraw Your Consent to our processing of your personal information. Please note that your withdrawal will only take effect for future processing and will not affect the lawfulness of processing before the withdrawal.
              </li>
              </ol>
              If you would like to exercise any of these rights, please contact us as set forth in “Contact Us” below. We will process such requests in accordance with applicable laws.
              </p>

              <h4>7. SECURITY OF YOUR INFORMATION</h4>
              <p>
              We take steps designed to ensure that your information is treated securely and in accordance with this Privacy Policy. Unfortunately, no system is 100% secure, and we cannot ensure or warrant the security of any information you provide to us. To the fullest extent permitted by applicable law, we do not accept liability for unauthorized disclosure.
              </p>
              <p>
              By using our Services or providing personal information to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of our Services. If we learn of a security system’s breach, we may attempt to notify you electronically by posting a notice with our Services, by mail, or by sending an email to you.
              </p>
              
              <h4>8. INTERNATIONAL DATA TRANSFERS</h4>
              <p>
              All information processed by us may be transferred, processed, and stored anywhere in the world, including, but not limited to, the United States or other countries, which may have data protection laws that are different from the laws where you live. We endeavor to safeguard your information consistent with the requirements of applicable laws.
              </p>

              <h4>9. RETENTION OF PERSONAL INFORMATION</h4>
              <p>
              We store the personal information we collect as described in this Privacy Policy for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws.
              </p>

              <h4>10. CHILDREN’S INFORMATION</h4>
              <p>
              Our Services is not directed to children under 13 (or other age as required by local law), and we do not knowingly collect personal information from children. If you learn that your child has provided us with personal information without your consent, you may contact us as set forth in “Contact Us” below. If we learn that we have collected a child’s personal information in violation of applicable law, we will promptly take steps to delete such information.
              </p>

              <h4>11. THIRD-PARTY WEBSITES/APPLICATIONS</h4>
              <p>
              Our Services may contain links to other Websites/applications and other Websites/applications may reference or link to our Services. These third-party services are not controlled by us. We encourage our users to read the privacy policies of each Websites and application with which they interact. We do not endorse, screen, or approve, and are not responsible for, the privacy practices or content of such other websites or applications. Providing personal information to third-party Websites or applications is at your own risk.
              </p>

              <h4>12. SUPERVISORY AUTHORITY</h4>
              <p>
              If you are located in the European Economic Area, Switzerland, or the United Kingdom you have the right to lodge a complaint with a supervisory authority if you believe our processing of your personal information violates applicable law.
              </p>

              <h4>CONTACT US</h4>
              <p>
              If you have any questions about our privacy practices or this Privacy Policy, or to exercise your rights as detailed in this Privacy Policy, please contact us at (info@blocunited.com)
              </p>

            </div>
            
            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
