import React from 'react';
import {Link} from 'react-router-dom';

function Footer() {
  return (
    <div>
      <footer className="footer__1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 space-y-20">
              <div className="footer__logo">
                <Link to="/">
                  <img src={`/img/logos/logo-sm.png`} alt="logo" id="logo_js_f" />
                </Link>
              </div>
              <p className="footer__text">
              TheTradingBloc is a shared liquidity NFT market smart contract
              </p>
              <div>
                <ul className="footer__social space-x-10 mb-40">
                  <li>
                    <a href="https://www.facebook.com/" rel="noreferrer"  target="_blank">
                      <i className="ri-facebook-line" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.messenger.com/" rel="noreferrer"  target="_blank">
                      <i className="ri-messenger-line" />
                    </a>
                  </li>
                  <li>
                    <a href="https://whatsapp.com" target="_blank" rel="noreferrer" >
                      <i className="ri-whatsapp-line"  />
                    </a>
                  </li>
                  <li>
                    <a href="https://youtube.com" target="_blank" rel="noreferrer" >
                      <i className="ri-youtube-line" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <h6 className="footer__title">TheTradingBloc</h6>
              <ul className="footer__list">
                <li>
                  <Link to="/"> Home </Link>
                </li>
                
                <li>
                  <a href="https://marketplace.thetradingbloc.com" target='_blank' rel="noreferrer" > NFT Marketplace</a>
                </li>
              </ul>
            </div>
            {/* <div className="col-lg-2 col-6">
              <h6 className="footer__title">Assets</h6>
              <ul className="footer__list">
                <li>
                  <Link to="profile"> Profile </Link>
                </li>
                <li>
                  <Link to="creators"> Creators </Link>
                </li>
                <li>
                  <Link to="collections"> Colletctions </Link>
                </li>
                <li>
                  <Link to="Activity"> Activity</Link>
                </li>
              </ul>
            </div> */}
            <div className="col-lg-2 col-6">
              <h6 className="footer__title">Products</h6>
              <ul className="footer__list">
                <li>
                  <Link to="/crypto-converter"> Crypto Converter </Link>
                </li>
                <li>
                  <Link to="/crypto-screener"> Crypto Screener </Link>
                </li>
                <li>
                  <Link to="/ico-ipo-calendar"> ICO/IPO Calendar</Link>
                </li>
                <li>
                  <Link to="/event-calendar"> Event Calendar </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6">
              <h6 className="footer__title">Terms</h6>
              <ul className="footer__list">
                <li>
                  <Link to="/disclaimer"> Disclaimer </Link>
                </li>
                <li>
                  <Link to="/privacy-policy"> Privacy Policy </Link>
                </li>
                <li>
                  <Link to="/terms"> Terms</Link>
                </li>
                {/* <li>
                  <Link to="/nft-purchase-agreement"> NFT Purchase Agreement </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <p className="copyright text-center">
            Copyright © 2022. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
