import "./assets/css/plugins/bootstrap.min.css";
import 'remixicon/fonts/remixicon.css'
import "./assets/scss/style.scss";

import { useEffect } from "react";

import AppRoutes from "./Router/routes";
function App() {

  


  return (
    <div className="App overflow-hidden">
      {/* <h1>Moralis Hello World!</h1>
      <button onClick={login}>Moralis Metamask Login</button>
      <button onClick={logOut} disabled={isAuthenticating}>Logout</button> */}
    
      <AppRoutes />
    </div>
  );
}

export default App;
