import { useState, createContext, useContext, useEffect } from "react";


const MarketPlaceContext = createContext(null);

export const MarketPlaceContextProvider = ({ children }) => {

  const [listedItems, setListedItems] = useState([]);
  const [purchasedNFTs, setPurchasedNFTs] = useState([]);
  const [marketPlaceNFTs, setMarketPlaceNFTs] = useState([]);
  const [listedItemCount, setListItemCount] = useState(0);
  const [purchasedItemCount, setPurchasedItemCount] = useState(0);
  const [loadingState, setLoadingState] = useState('not-loaded')
  const [error, setError] = useState('');
  const [message, setMessage] = useState('')


  // const login = user => {
  //   localStorage.setItem('userInfo', JSON.stringify(user));

  // }

  /* Returns only items a user has listed */
  async function getListedItems() {
    
  }
  /* Returns all unsold market items */
  async function getUnsoldMarketItems() {
    
  }

  /* Returns only items that a user has purchased */
  async function getPurchasedItems() {
    
  }


  async function buyNFT(nft) {
    
  }

  async function resellNFT(nft) {
    
  }

  useEffect(() => {
   
  }, [])

  return <MarketPlaceContext.Provider value={{
   
    loadingState,
    listedItems,
    purchasedNFTs,
    marketPlaceNFTs,
    listedItemCount,
    purchasedItemCount,
    error,
    message,
    getUnsoldMarketItems,
    getPurchasedItems,
    getListedItems,
    buyNFT,
    resellNFT
  }} >{children}</MarketPlaceContext.Provider>
}

export const useMarketPlaceContext = () => {
  return useContext(MarketPlaceContext);
}