 import React from 'react';
// import ReactDOM from 'react-dom';
 import App from './App';
 

// ReactDOM.render(
//   <App />,
//   document.getElementById('root')
// );


import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { MarketPlaceContextProvider } from './context/MarketPlaceContext';
import ScrollToTop from './hooks/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <MarketPlaceContextProvider>
  <React.StrictMode>
    <BrowserRouter>
    <ScrollToTop>
    <App />
    </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>
  </MarketPlaceContextProvider>
  </AuthProvider>
);

reportWebVitals();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
